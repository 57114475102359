import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";

const utilTemplate = {
  // templatesテーブル及びcontentFormatsテーブルのデータを読み込む
  async setStore() {
    const TemplateRepository = await repositoryFactory.get("templates");
    const contentFormatsRepository = await repositoryFactory.get(
      "contentFormats"
    );

    // ローディング表示
    store.dispatch("loadingIcon/showIcon");

    store.dispatch("templates/clear");
    await TemplateRepository.list(store.getters["shops/currentShop"].id)
      .then((response) => {
        if (response.data) {
          store.dispatch("templates/setTemplateList", response.data);
        }
      })
      .catch((error) => {
        throw "ERROR:データ取得エラー (" + error + ")";
      });

    // コンテンツフォーマット取得
    await contentFormatsRepository
      .list()
      .then((response) => {
        if (response.data) {
          store.dispatch("templates/setContentFormatList", response.data);
        }
      })
      .catch((error) => {
        throw (
          "ERROR:templateDialogComponent.vue/init contentFormatsRepository.list (" +
          error +
          ")"
        );
      });

    store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    return true;
  },
  // templatesテーブルのデータ1件削除する
  async deleteStore(id) {
    const TemplateRepository = await repositoryFactory.get("templates");
    // ローディング表示
    store.dispatch("loadingIcon/showIcon");
    // データ削除処理(is_activeをfalseに)
    const result = await TemplateRepository.delete(id)
      .then((response) => {
        // 更新OK
        return true;
      })
      .catch((error) => {
        throw (
          "ERROR:ShopSite.vue/deleteItem ShopSitesRepository.delete (" +
          error +
          ")"
        );
      })
      .finally(() => {
        store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
      });

    if (result) await store.dispatch("templates/deleteTemplate", id);
    return result;
  },
};

export default utilTemplate;
