<template>
  <div>
    <v-tabs v-model="tab_items" height="32" color="teal" hide-slider>
      <v-tabs-slider></v-tabs-slider>
      <v-tab v-for="tab in tabItems" :key="tab.name" v-show="tab.show">
        <v-icon v-if="tab.icon" dense class="mr-1">{{ tab.icon }}</v-icon
        >{{ tab.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab_items" touchless>
      <v-tab-item v-for="tab in tabItems" :key="tab.name" eager>
        <template v-if="tab.index != 99999">
          <component
            v-bind:is="tab.component"
            :ref="`set_time_com` + tab.index"
            :child_update_header_id="tab.id"
            :site_content="props.site_content"
            :tab_index="tab.tab_index"
            :mode="setting.mode_specify"
            :child_update_contents="tab.child_update_contents"
          ></component>
        </template>
        <template v-if="tab.index == 99999">
          <component
            v-bind:is="tab.component"
            :ref="`set_time_com` + tab.index"
            :set_weeks="tab.setWeeks"
            :set_days="tab.setDays"
            @select_week="selectWeek"
            @select_day="selectDay"
          ></component>
        </template>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import setDetailComponent from "./setDetail.vue";
import setTimeCogComponent from "./setTimeCog.vue";

export default defineComponent({
  components: {
    setDetailComponent,
    setTimeCogComponent,
  },
  props: ["use_template", "child_content_header", "site_content"],
  setup(props, ctx) {
    const childUpdateContentsRepository = repositoryFactory.get(
      "childUpdateContents"
    );
    const childUpdateHeaderRepository =
      repositoryFactory.get("childUpdateHeaders");
    const state = reactive({
      useTemplate: null,
      tabItems: [],
    });
    const tab_items = ref();
    const tabList = [];

    const getTabText = (post_year, post_month, post_day, post_week) => {
      let text = "";
      if (post_year != null) {
        text = text + post_year + "年";
      }
      if (post_month != null) {
        text = text + post_month + "月";
      }
      if (post_day != null) {
        text = text + post_day + "日";
      }
      if (post_week != null) {
        text =
          text == ""
            ? setting.weekday[post_week] + "曜日"
            : text + " [" + setting.weekday[post_week] + "]";
      }
      if (text == "") {
        text = "基本設定";
      }
      return text;
    };

    // 空タブを追加する indexが-1の時、末尾に1行追加する
    const addTab = (index) => {
      if (index == -1) {
        index = tabList.length;
      }
      tabList.push({
        id: 0,
        update_content_id: null,
        mode: setting.mode_specify,
        interval_minute: null,
        tab_index: index,
        post_year: null,
        post_month: null,
        post_day: null,
        post_week: null,
        config: "",
        index: index,
        name: getTabText(null, null, null, null),
        component: setDetailComponent,
        show: true,
        icon: "",
      });
      return index;
    };

    const setTab = (data) => {
      // child_update_headerに関する内容
      set(state.tabItems[data.tab_index], "id", data.id);
      set(
        state.tabItems[data.tab_index],
        "update_content_id",
        data.update_content_id
      );
      set(state.tabItems[data.tab_index], "mode", data.mode);
      set(
        state.tabItems[data.tab_index],
        "interval_minute",
        data.interval_minute
      );
      set(state.tabItems[data.tab_index], "tab_index", data.tab_index);
      set(state.tabItems[data.tab_index], "post_year", data.post_year);
      set(state.tabItems[data.tab_index], "post_month", data.post_month);
      set(state.tabItems[data.tab_index], "post_day", data.post_day);
      set(state.tabItems[data.tab_index], "post_week", data.post_week);
      set(state.tabItems[data.tab_index], "config", data.config);
      set(
        state.tabItems[data.tab_index],
        "child_update_contents",
        data.child_update_contents
      );
    };

    const setData = async () => {
      // 基本設定タブ
      addTab(0);
      // 日曜～土曜タブ
      for (let i = 1; i <= 7; i++) {
        addTab(i);
        set(tabList[i], "show", false);
        set(tabList[i], "post_week", i - 1);
        set(tabList[i], "name", getTabText(null, null, null, i - 1));
        set(tabList[i], "child_update_contents", []);
      }
      // 1日～31日タブ
      let dayIndex = 0;
      for (let i = 1; i <= 31; i++) {
        dayIndex = addTab(-1);
        set(tabList[dayIndex], "show", false);
        set(tabList[dayIndex], "post_day", i);
        set(tabList[dayIndex], "name", getTabText(null, null, i, null));
        set(tabList[dayIndex], "child_update_contents", []);
      }
      // "曜日・日時指定"タブ追加
      const new_index = addTab(-1);
      set(tabList[new_index], "tab_index", 99999);
      set(tabList[new_index], "index", 99999);
      set(tabList[new_index], "name", "曜日・日別設定");
      set(tabList[new_index], "component", setTimeCogComponent);
      set(tabList[new_index], "icon", "mdi-calendar-month");

      // Tab生成
      for (let i = 0; i < tabList.length; i++) {
        state.tabItems.push(tabList[i]);
      }

      const cuc = props.child_content_header;
      Object.keys(cuc).forEach(function (key) {
        setTab(cuc[key]);
        set(state.tabItems[cuc[key].tab_index], "show", true);
      });

      // 曜日にチェックをいれておく
      const setWeeks = [];
      state.tabItems.forEach((tab) => {
        if (
          tab.show &&
          tab.tab_index != 0 &&
          tab.tab_index >= 1 &&
          tab.tab_index <= 7
        ) {
          setWeeks.push(tab.post_week);
        }
      });
      set(state.tabItems[new_index], "setWeeks", setWeeks);

      // 日付にチェックをいれておく
      const setDays = [];
      state.tabItems.forEach((tab) => {
        if (
          tab.show &&
          tab.tab_index != 0 &&
          tab.tab_index >= 8 &&
          tab.tab_index <= 38
        ) {
          setDays.push(tab.post_day);
        }
      });
      set(state.tabItems[new_index], "setDays", setDays);
    };

    const init = async () => {
      await setData();
    };

    init();

    // 保存前のチェック
    const checkPreSave = async () => {
      // タブ毎に保存前のチェックを実施していく
      for (let i = 0; i < state.tabItems.length; i++) {
        if (state.tabItems[i].show && state.tabItems[i].index != 99999) {
          // 時刻指定コンポーネント
          const tab_component =
            ctx.refs["set_time_com" + state.tabItems[i].index][0];
          if (!(await tab_component.checkPreSave())) {
            tab_items.value = state.tabItems[i].index;
            return false;
          }
        }
      }
      // タブ毎の設定件数を確認する
      let zeroDetailTab = [];
      for (let i = 0; i < state.tabItems.length; i++) {
        if (state.tabItems[i].show && state.tabItems[i].index != 99999) {
          const tab_component =
            ctx.refs["set_time_com" + state.tabItems[i].index][0];
          if (tab_component.getDetailCount() == 0) {
            zeroDetailTab.push(state.tabItems[i].name);
          }
        }
      }
      if (zeroDetailTab.length > 0) {
        if (
          !window.confirm(
            "設定されていないタブがあります。このまま続行しますか？\n" +
              zeroDetailTab.join(", ") +
              "\n\n※設定の無い日は、自動更新を行いません。"
          )
        ) {
          return false;
        }
      }
      return true;
    };

    // 保存処理 child_update_headerの更新
    const save = async (saveUpdateContents) => {
      // 一旦、該当のchild_update_headerを全削除する
      const del_params = {
        update_content_id: saveUpdateContents.id,
        mode: setting.mode_specify,
      };
      await childUpdateHeaderRepository
        .deletelist(del_params)
        .then((response) => {
          return true;
        })
        .catch((error) => {
          throw (
            "ERROR:setTime.vue/save childUpdateContentsRepository.deletelist (" +
            error +
            ")"
          );
        });

      // 表示中のタブを新規追加
      for (let i = 0; i < state.tabItems.length; i++) {
        if (
          state.tabItems[i].show == true &&
          state.tabItems[i].index != 99999
        ) {
          let tmpPostWeek = null;
          let tmpPostDay = null;
          if (state.tabItems[i].index >= 1 && state.tabItems[i].index <= 7) {
            tmpPostWeek = state.tabItems[i].index - 1;
          }
          if (state.tabItems[i].index >= 8 && state.tabItems[i].index <= 38) {
            tmpPostDay = state.tabItems[i].index - 7;
          }
          const createParam = {
            "update_content_id": saveUpdateContents.id,
            "mode": setting.mode_specify,
            "tab_index": state.tabItems[i].index,
            "post_day": tmpPostDay,
            "post_week": tmpPostWeek,
          };
          const child_update_header_id = await childUpdateHeaderRepository
            .create(createParam)
            .then((response) => {
              return response.data.id;
            })
            .catch((error) => {
              throw (
                "ERROR:tabSpecify.vue/save childUpdateHeaderRepository.create (" +
                error +
                ")"
              );
            });
          state.tabItems[i].id = child_update_header_id;
        }
      }
      // 子コンポーネントから保存内容を取得しておき、まとめて保存する
      let child_update_contents = [];
      for (let i = 0; i < state.tabItems.length; i++) {
        if (
          state.tabItems[i].show == true &&
          state.tabItems[i].index != 99999
        ) {
          const tab_component =
            ctx.refs["set_time_com" + state.tabItems[i].index][0];
          const saveList = tab_component.save(state.tabItems[i].id);
          if (saveList.length > 0) {
            child_update_contents = child_update_contents.concat(saveList);
          }
        }
      }
      // 一括保存処理
      await childUpdateContentsRepository
        .save({ child_update_content: child_update_contents })
        .then((response) => {
          return true;
        })
        .catch((error) => {
          throw (
            "ERROR:setTime.vue/save childUpdateContentsRepository.save (" +
            error +
            ")"
          );
        });

      return true;
    };

    // 曜日選択画面でチェックされた曜日を表示する
    const selectWeek = (items) => {
      items.forEach((item) => {
        const tab = tabList.find((t) => t.index === item.index);
        tab.show = item.checked;
      });
    };

    // 日付選択画面でチェックされた日付を表示する
    const selectDay = (items) => {
      items.forEach((item) => {
        const tab = tabList.find((t) => t.index === item.index);
        tab.show = item.checked;
      });
    };

    // modeを返す
    const getMode = () => {
      return setting.mode_specify;
    };

    return {
      setting,
      tab_items,
      props,
      ...toRefs(state),
      setData,
      checkPreSave,
      save,
      selectWeek,
      selectDay,
      getMode,
    };
  },
});
</script>

<style scoped>
.v-tab--active {
  background-color: #e8f5e9;
}
</style>
